import React from 'react';
import bugsnag from '@bugsnag/js';
import bugsnagReact from '@bugsnag/plugin-react';
import { css, jsx } from '@emotion/core';

import { RouteComponentProps } from '@reach/router';
import Layout from '../components/Layout';

const bugsnagClient = bugsnag('cf7c462dfc177ebe25fcf13ab11a360b');
bugsnagClient.use(bugsnagReact, React);

const ErrorBoundary = bugsnagClient.getPlugin('react');

const Page = (props: RouteComponentProps) => {
    return (
        <ErrorBoundary>
            <Layout>
                <div className="container mx-auto mb-12 mt-12">I do things</div>
                <div className="container mx-auto mt-12 px-4 font-sans">
                    <h4 className="uppercase mb-10">How it works</h4>
                    <div className="px-2">
                        <div className="flex flex-wrap -mx-2">
                            <div className="w-full lg:w-1/3 px-2 mb-6 flex flex-wrap">
                                <div className="font-bold text-xl text-white bg-blue-700 rounded-full h-12 w-12 flex items-center justify-center">
                                    1
                                </div>
                                <div className="text-right ml-4 mt-3">
                                    <h4 className="text-blue-700 text-lg">
                                        Step 1
                                    </h4>
                                </div>
                                <div className="w-full mt-2 px-4">Party</div>
                            </div>
                            <div className="w-full lg:w-1/3 px-2 mb-6 flex flex-wrap">
                                <div className="font-bold text-xl text-white bg-blue-700 rounded-full h-12 w-12 flex items-center justify-center">
                                    2
                                </div>
                                <div className="text-right ml-4 mt-3">
                                    <h4 className="text-blue-700 text-lg">
                                        Step 2
                                    </h4>
                                </div>
                                <div className="w-full mt-2 px-4">
                                    all of the
                                </div>
                            </div>
                            <div className="w-full lg:w-1/3 px-2 mb-6 flex flex-wrap">
                                <div className="font-bold text-xl text-white bg-blue-700 rounded-full h-12 w-12 flex items-center justify-center">
                                    3
                                </div>
                                <div className="text-right ml-4 mt-3">
                                    <h4 className="text-blue-700 text-lg">
                                        Step 3
                                    </h4>
                                </div>
                                <div className="w-full mt-2 px-4">Time!</div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </ErrorBoundary>
    );
};

export default Page;
